.hp-style {
  .cardTitle {
    padding: 20px 5px 0px 5px;
    font-size: 1.2rem;
    text-align: start;
    font-weight: 400;
  }
  
  .hp-carouselth {
    .carousel-inner {
      // @media only screen and (min-width: 0px) and (max-width: 1458px) {
      //   height: 340px !important;
      // }
      // @media only screen and (min-width: 1100px) and (max-width: 1258px) {
      //   height: 320px !important;
      // }
      // @media only screen and (min-width: 992px) and (max-width: 1100px) {
      //   height: 260px !important;
      // }
      // @media only screen and (min-width: 768px) and (max-width: 992px) {
      //   height: 250px !important;
      // }
      // @media only screen and (min-width: 544px) and (max-width: 768px) {
      //   height: 521px !important;
      // }
      // @media only screen and (min-width: 380px) and (max-width: 544px) {
      //   height: 350px !important;
      // }
      // @media only screen and (min-width: 280px) and (max-width: 380px) {
      //   height: 250px !important;
      // }
      // @media only screen and (min-width: 0px) and (max-width: 280px) {
      //   height: 200px !important;
      // }
      height: auto;
      // height: 390px !important;
      transition: height 0.3s ease;
      a {
        img {
          // @media only screen and (min-width: 0px) and (max-width: 1458px) {
          //   height: 340px !important;
          // }
          // @media only screen and (min-width: 1100px) and (max-width: 1258px) {
          //   height: 320px !important;
          // }
          // @media only screen and (min-width: 992px) and (max-width: 1100px) {
          //   height: 260px !important;
          // }
          // @media only screen and (min-width: 768px) and (max-width: 992px) {
          //   height: 250px !important;
          // }
          // @media only screen and (min-width: 544px) and (max-width: 768px) {
          //   height: 521px !important;
          // }
          // @media only screen and (min-width: 380px) and (max-width: 544px) {
          //   height: 350px !important;
          // }
          // @media only screen and (min-width: 280px) and (max-width: 380px) {
          //   height: 250px !important;
          // }
          // @media only screen and (min-width: 0px) and (max-width: 280px) {
          //   height: 200px !important;
          // }
          height: auto;
          // height: 390px !important;
          transition: height 0.3s ease;
        }
      }
    }
  }

  .hp-carouselgo {
    .carousel-inner {
      // @media only screen and (min-width: 0px) and (max-width: 1458px) {
      //   height: 200px !important;
      // }
      // @media only screen and (min-width: 1100px) and (max-width: 1258px) {
      //   height: 200px !important;
      // }
      // @media only screen and (min-width: 992px) and (max-width: 1100px) {
      //   height: 180px !important;
      // }
      // @media only screen and (min-width: 768px) and (max-width: 992px) {
      //   height: 150px !important;
      // }
      // @media only screen and (min-width: 544px) and (max-width: 768px) {
      //   height: 521px !important;
      // }
      // @media only screen and (min-width: 380px) and (max-width: 544px) {
      //   height: 350px !important;
      // }
      // @media only screen and (min-width: 280px) and (max-width: 380px) {
      //   height: 250px !important;
      // }
      // @media only screen and (min-width: 0px) and (max-width: 280px) {
      //   height: 200px !important;
      // }
      height: auto;
      transition: height 0.3s ease;
      a {
        img {
          // @media only screen and (min-width: 0px) and (max-width: 1458px) {
          //   height: 200px !important;
          // }
          // @media only screen and (min-width: 1100px) and (max-width: 1258px) {
          //   height: 200px !important;
          // }
          // @media only screen and (min-width: 992px) and (max-width: 1100px) {
          //   height: 180px !important;
          // }
          // @media only screen and (min-width: 768px) and (max-width: 992px) {
          //   height: 150px !important;
          // }
          // @media only screen and (min-width: 544px) and (max-width: 768px) {
          //   height: 521px !important;
          // }
          // @media only screen and (min-width: 380px) and (max-width: 544px) {
          //   height: 350px !important;
          // }
          // @media only screen and (min-width: 280px) and (max-width: 380px) {
          //   height: 250px !important;
          // }
          // @media only screen and (min-width: 0px) and (max-width: 280px) {
          //   height: 200px !important;
          // }
          height: auto;
          transition: height 0.3s ease;
        }
      }
    }
  }

  .categories-card {
    @media only screen and (min-width: 0px) and (max-width: 1258px) {
      height: 100px !important;
    }
    @media only screen and (min-width: 992px) and (max-width: 1100px) {
      height: 87px !important;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      height: 280px !important;
    }
    @media only screen and (min-width: 444px) and (max-width: 768px) {
      height: 170px !important;
    }
    @media only screen and (min-width: 0px) and (max-width: 444px) {
      height: 120px !important;
    }
    // height: 133px !important;
    transition: height 0.3s ease;
    object-fit: cover !important;
    img {
      @media only screen and (min-width: 0px) and (max-width: 1250px) {
        height: 100px !important;
      }
      @media only screen and (min-width: 992px) and (max-width: 1100px) {
        height: 87px !important;
      }
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        height: 280px !important;
      }
      @media only screen and (min-width: 444px) and (max-width: 768px) {
        height: 170px !important;
      }
      @media only screen and (min-width: 0px) and (max-width: 444px) {
        height: 120px !important;
      }
      // height: 133px !important;
      object-fit: cover !important;
      transition: height 0.3s ease;
    }
  }

  .cardItem {
    .cardItem-item:hover {
      box-shadow: 0 2px 8px #00000026;
    }
    .MuiImageListItem-item {
      overflow: unset !important;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 45% !important;
    }
    height: auto !important;
    padding: 5px !important;
    position: relative;
    .cardItem-item {
      background-color: #fff;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.2s ease-in-out;
      border-radius: 8px;
      border: 0.7px solid #00000026;
    }
    .imgCard {
      height: 210px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        height: 150px;
      }
    }
    .img-card {
      span {
        width: 100%;
      }
      min-height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .MuiSkeleton-pulse {
        background: #ebebeb;
      }
      .img-item {
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          height: 150px;
        }
        width: 100%;
        height: 210px;
        object-fit: contain;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        z-index: 2;
        position: relative;
        padding: 20px;
      }
    }
    .card-content {
      .title-card {
        display: block;
        min-height: 61px;
        max-height: 62px;
        width: 100%;
      }
      .brand {
        font-size: 12px;
        color: #8b8b8b;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        position: relative;
        width: 100%;
        margin: 0;
      }
      .rating {
        font-size: 12px;
      }
      .MuiSkeleton-pulse {
        background: #ebebeb;
      }
      padding: 10px;
      height: 130px;
      .content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
      }
      h4 {
        font-size: 14px;
        line-height: 1.5;
        color: #444;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: relative;
        width: 100%;
        margin: 0;
      }
      h5 {
        margin: 0;
        font-size: 16px;
        color: #444;
      }
    }
  }
  .adcb-short {
    position: absolute;
    z-index: 111;

    bottom: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      bottom: 18px;
    }
  }

  .Dashboard-CL-CRD::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: #eee;
    border-radius: 100px;
  }
  .Dashboard-CL-CRD::-webkit-scrollbar-thumb:horizontal {
    background: gray;
    border-radius: 10px;
  }

  .Dashboard-CL5-CRD::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: #eee;
    border-radius: 100px;
  }
  .Dashboard-CL5-CRD::-webkit-scrollbar-thumb:horizontal {
    background: gray;
    border-radius: 10px;
  }

  .Dashboard-board-img {
    width: 100%;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
    }
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none !important;
}
.delivery-days {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 9.5px;
  }
  position: absolute;
  right: 10px;
  bottom: 0px;
  color: rgb(0, 0, 0);
  font-size: 10px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scroll-back_cards {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
  }
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 111;
  margin: 0px 5px;
}
.scroll-next_cards {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
  }
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 111;
  margin: 0px 5px;
}

.scroll-back_cards-search {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
  }
  position: absolute;
  left: -15px;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 111;
  margin: 0px 5px;
}
.scroll-next_cards-search {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
  }
  position: absolute;
  right: -15px;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 111;
  margin: 0px 5px;
}
