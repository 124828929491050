$font-size: 1rem;
$line-height: 1.4;
$lines-to-show-heading: 3;
$lines-to-show-text: 4;
$lines-to-show-author: 2;
.Search-page {
  .add-to-library-button {
    cursor: pointer;
  }
  .Category-item {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 50% !important;
    }
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .category-books-section {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
    padding: 20px;
    background: #fcfcfc;
  }
  .category-bottom-paginattion {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 50px 0px 50px 50px;
  }
  .category-search-form {
    padding-right: 40px;
    box-shadow: none !important;
  }
  .categogy-subscribe-nowL {
    color: #212945;
    letter-spacing: 0.2px;
    padding-left: 5px;
    cursor: pointer;
  }
  .search-text-on-hover {
    display: none;
  }
  .categogy-subscribe-nowL:hover {
    .search-text-on-hover {
      display: block !important;
    }
  }
  .CategoryLibIcon {
    font-size: 18px;
    margin-top: 1px;
    color: #21294554;
  }
  .CategprySpinner {
    font-size: 8px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-top: 5px;
  }
  .categogy-subscribe-now {
    color: #212945;
    letter-spacing: 0.2px;
  }
  .category-search-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
  }
  .btn-info {
    color: #081622 !important;
    background-color: #fcfcfc !important;
    border: 1px solid #e0e0e0 !important;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    display: flex;
    align-items: center;
  }
  .pagination-card {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .pagination-item-lang {
    margin-right: 15px;
  }
  .pagination-item-ar {
    margin-left: 15px;
  }

  .Lit-Banner {
    .Lit-banner-card {
      .Banner-text {
        .Banner-text-style {
          position: relative;
          display: flex;
          justify-content: center;
          background: #fcfcfc;
          img {
            object-fit: cover;
          }
          .category-banner-text-section {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            padding-top: 30px;
            padding-bottom: 20px;
          }
          .category-banner-text-card {
            width: 550px;
            max-width: 100%;
            text-align: center;
          }
          h3 {
            font-weight: 600;
            font-size: 2rem;
          }
        }
      }
      .Banner-image {
        display: flex;
        justify-content: flex-end;
        border-top-left-radius: 0px;
        img {
          width: 275px;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .img-list-card:hover {
    background-color: #0e405914;
  }
  .img-list-card {
    // background-color: #fcfcfc;
    // padding: 20px;
    // min-height: 50%;
    // display: flex !important;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: #fff0;
    padding-bottom: 20px;
  }
  .img-list-img {
    // height: 228px;
    // object-fit: contain;
    // max-width: 100%;
    // margin-right: auto;
    // margin-left: auto;
    // z-index: 2;
    width: 100%;
    height: undefined;
    aspect-ratio: 1;
  }
  .Category-text {
    width: 100%;
    padding-left: 15px;
    padding-right: 10px;
    h4 {
      font-weight: 600;
      display: -webkit-box;
      margin: 0;
      font-size: $font-size;
      -webkit-line-clamp: $lines-to-show-heading;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 500;
      color: #7f7f7f;
      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 5px;
      margin: 0;
    }
    p {
      margin: 0;
      color: #545657;
      display: -webkit-box;
      margin: 0;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      padding-top: 5px;
    }
  }
  .category-books-heading {
    font-size: 28px;
    margin: 0;
  }
  .category-view-more {
    padding: 40px;
    text-align: center;
  }
}
.category-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  // input {
  //   width: 300px;
  //   height: 45px;
  //   border: 1px solid #eee;
  // }
  // button {
  //   position: absolute;
  //   right: 10px;
  //   top: 14px;
  // }
}
.paginattion-search {
  display: flex;
  align-items: center;
}
.pagination-form-card {
  position: relative;
  width: 300px;
  max-width: 100%;
  margin-right: 10px;
}

.books-card-dropdown {
  float: right;
  svg {
    font-size: 17px;
    margin: 0;
    color: #696f75;
  }
}

.books-card-footer-size {
  font-size: 15px;
  color: #696f75;
}
.category-view-more {
  width: 100%;
}
