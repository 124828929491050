.bouncing-loader {
  display: flex;
  justify-content: center;
}

.cardTitle{
  .bouncing-loader > div {
    width: 12px;
    height: 12px;
    margin: 1px 3px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
}

.bouncing-loader > div {
  width: 6px;
  height: 6px;
  margin: 1px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-0px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
