.Account-page {
  .op-section {
    .oip-f-button {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 100%;
      }
    }
    .oip-f-jc-c {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        justify-content: center;
        display: block;
      }
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .oip-f-status {
      text-align: center;
      padding: 0px 0px 0px 0px;
    }
    .op-o-info {
      @media only screen and (min-width: 0px) and (max-width: 1140px) {
        white-space: normal;
        text-align: start;
      }
      text-align: right;
      white-space: nowrap;
    }

    .op-item-card-section::-webkit-scrollbar {
      height: 4px;
      background-color: #eee;
    }
    .op-item-card-section::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 100px;
    }

    .op-item-card-section {
      @media only screen and (min-width: 0px) and (max-width: 1140px) {
        text-align: center;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
      }
    }
    .op-item-card-section-item {
      display: inline-flex;
    }
    .op-card {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 20px;
      background-color: #fcfcfc;
      .op-card-header {
        @media only screen and (min-width: 0px) and (max-width: 1140px) {
          display: grid;
          justify-content: flex-start;
        }
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          flex-wrap: wrap;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;
      }
      .op-status-title {
        text-transform: capitalize;
        font-size: 1rem;
      }
      .op-item-section {
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          flex-wrap: nowrap;
        }
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .op-item-card {
          @media only screen and (min-width: 0px) and (max-width: 992px) {
            flex-wrap: wrap;
            justify-content: center;
          }
          align-items: center;
          display: flex;
          margin-bottom: 15px;
          border-radius: 10px;
          border: 1px solid #eeee;
          // border: 1px solid #eeeeee;
          // padding: 15px;
          border-radius: 10px;
          .op-item-img {
            max-width: 100px;
            min-width: 100px;
            min-height: 100px;
            max-height: 100px;
            object-fit: contain;
            padding: 7px;
            border-radius: 6px;
          }
          .op-item-title {
            margin: 0px 10px;
            width: 100%;
            a {
              color: #444;
            }
            p {
              font-size: 13px;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
              position: relative;
            }
            .op-item-content {
              @media only screen and (min-width: 0px) and (max-width: 992px) {
                flex-wrap: wrap;
                span {
                  margin: 0 !important;
                }
              }
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }

  .profile-card {
    background-color: #fcfcfc;
    padding: 30px 15px 15px 15px;
  }
}
.payment-method-card {
  margin-bottom: 15px;
  .list {
    border-radius: 4px;
    border: 1px solid #eee;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart-type {
    text-transform: capitalize;
  }
}
.payment-method-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-method-button {
  margin-right: 20px;
}

.tabel_cards {
  .productMaincard {
    padding: 15px !important;
  }
  .table_search {
    height: 40px;
    min-width: 250px;
    border: 1px solid #e9e9e9 !important;
  }
  .datePicker {
    height: 40px;
    border: 1px solid #e9e9e9 !important;
  }
  .table_search_card {
    position: relative;
    display: flex;
    align-items: center;
  }

  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    top: 11px;
    right: 10px;
  }

  .tabelOverflow {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 10px 17px 10px 17px !important;
    border: 1px solid #dee2e654;
    color: #5c5e5f;
    font-size: 14px;
    background: #fcfcfc;
  }
  .table {
    margin-bottom: 0 !important;
  }
  td:first-child {
    border-left: none !important;
  }
  td:last-child {
    border-right: none !important;
  }
  th:first-child {
    border-left: none !important;
    width: 10px;
  }
  th:nth-child(2) {
    width: 10px;
  }
  th:last-child {
    border-right: none !important;
  }
  .dropdown .dropDown_Btn {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #e9e9e9;
    color: #444;
    justify-content: center;
    padding: 8px;
  }
  .dropdown .dropdown-toggle-split {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #e9e9e9;
    color: #444;
    justify-content: center;
    padding: 8px;
  }

  .productSubcard {
    border: 1px solid #eff1f3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .productSubcard:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02) !important;
  }

  .displayFlex {
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .storeCard {
    padding: 30px;
    background-color: #fff;
  }
  .storeCardB {
    padding: 0px;
  }
  .storeCardH {
    padding: 0px;
  }
  .filDisF {
    display: flex;
    align-items: center;
  }
  .dFlexL {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .dFlex {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .sendStatus {
    background: #80c788;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 1px 8px 3px 8px;
  }

  .cardImage {
    margin-right: 5px;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 10%;
    padding-right: 20px;
  }

  .cardImages {
    height: 60px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;
  }

  .cardTitle {
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 5px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2e2f31;
    line-height: 1.2;
    width: 90%;
  }

  .manageProducts {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    background: #f3f2ee;
  }

  .manageButtons {
    margin-right: 10px;
  }

  .manageButtonsmrgn {
    margin-right: 0px;
    border-right: 1px solid #c3bfbf;
    color: #444 !important;
    padding: 0px 10px 0px 10px;
    font-size: 12px;
  }

  .spinLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5eb2d9;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .spinLoading {
      padding-left: 0px;
    }
  }

  .updateButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    overflow: auto;
    white-space: nowrap;
    width: 100%;
  }

  .infoCard {
    display: flex;
    align-items: center;
  }

  .updateIcon {
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .fsize12 {
    display: flex;
    font-size: 12px;
    padding-left: 20px;
  }
  .pagiStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
  }

  .subPagi {
    margin-left: 1px;
  }
  .btn-group-sm > .btn {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
  }
  .card-header {
    text-align: right;
    padding: 0;
    border: none;
  }

  .card {
    border: none;
    border-bottom: 1px solid #e0e0e0ee;
  }

  .det_Sty {
    text-align: left;
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 1px solid #eee;
  }
  .detialsView {
    cursor: pointer;
    padding: 10px;
    background: #fff0;
  }
  .pageSz {
    margin-right: 20px;
  }

  .fileUpload {
    padding: 7px;
    font-size: 12px;
    margin-bottom: 10px;
    height: 40px;
  }
  .FormGroupmrgn {
    margin: 0;
  }
  .successHeadng {
    color: #28a745;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .errorHeadng {
    color: #dc3545;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .uploadSpin {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .viewMr {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12px;
    z-index: 111;
  }
  .upldInv {
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #17a2b8;
  }
  .newFlex {
    display: flex;
    align-items: center;
  }
  .newFlex2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #eee;
  }
  .lastBorder {
    border-right: none !important;
  }
  .subPagi Button {
    border: none;
    border-right: 1px solid #eee;
    background-color: #fff !important;
    color: #444 !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    height: 27px;
  }
  .subPagi Button:hover {
    background-color: #eee !important;
    color: #444 !important;
    border: 1px solid #e9e9e9 !important;
  }
  .subPagi Button:focus {
    outline: none !important;
  }
  .newFlexPagi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
    padding: 10px 0px 10px 20px !important;
    background: rgba(247, 248, 255, 0.5);
  }
  .txtcntr {
    text-align: left;
    margin-top: 10px;
  }
  .upldInv {
    justify-content: space-between;
  }
  .BrdrRound {
    position: relative;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .cardImage {
      width: 100%;
      margin-top: 10px;
    }
    .displayFlex {
      display: block;
    }
    .cardTitle {
      width: 100%;
      margin-top: 10px;
    }
    .manageProducts {
      overflow: auto;
      white-space: nowrap;
    }
    .stchLeft {
      padding-right: 10px;
    }
    .hdMob {
      display: none;
    }
    .hdDask {
      display: block !important;
    }
    .pagiStyle {
      overflow: auto;
      white-space: nowrap;
    }
    .mbSzAdd {
      text-align: left;
      display: block;
    }
  }
  .manageProducts {
    overflow: auto;
    white-space: nowrap;
  }

  .btnUploadIn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .table {
    text-align: left;
    box-shadow: 0 0px 10px #ccc;
  }
  .listHeadng {
    margin: 0;
    text-align: center;
  }
  .actionBDiv {
    display: flex;
    align-items: center;
  }
  .actionButton {
    margin-right: 10px;
  }
  .actionButtonIcon {
    font-size: 14px;
  }
  .brdrLdng {
    border-top: 6.5px solid rgb(248, 248, 248);
  }
  .mrgnRight {
    margin-right: 10px;
  }
  .pdngStyle {
    font-size: 13px;
  }
  .crtCamp {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .crtCampS {
    display: flex;
    align-items: center;
  }
  .afterScheduled {
    display: flex;
    align-items: center;
  }
  .shcldLft {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .campSpace {
    margin-top: 7px;
  }
}

.dlrdflInput {
  height: 50px !important;
}
.dlrdflUpload {
  height: 50px !important;
  padding: 9px !important;
}
.fileUploadmsg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new_Modal {
  .modal-dialog {
    max-width: 40% !important;
  }
  .modal-body {
    padding: 1rem !important;
  }
}

.Item-modal {
  .modal-dialog {
    max-width: 850px !important;
    width: 100% !important;
  }
}

.sticky-column {
  right: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.data_not-found {
  font-size: 15px;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  padding: 20px 0px 20px 0px;
}
.no-data-found {
  padding: 20px;
  width: 100%;
  text-align: center;
}
.modal-header-ntv {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  button {
    margin: 0 !important;
    padding: 10px !important;
  }
}
.table td,
.table th {
  vertical-align: middle !important;
}

.ap-title {
  font-weight: 500;
  margin: 0;
  font-size: 1.2rem;
  padding: 20px 0px 20px 0px;
  text-align: center;
}

.o-summary-l {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee82;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.step-item-list {
  gap: 2px !important;
  margin-top: 5px;
}

.animated-border {
  border: 2px solid #ccc; /* Initial border color */
}

@keyframes changeBorderColor {
  0% {
    border-color: #fff; /* Dark border color */
  }
  50% {
    border-color: #fff0; /* Light border color */
  }
  100% {
    border-color: #fff; /* Dark border color */
  }
}

.MuiStepper-vertical .Mui-active .MuiStepIndicator-root {
  animation: changeBorderColor 2s infinite;
}

@keyframes changeBorderColorcc {
  0% {
    border-color: #0a6bcb; /* Dark border color */
  }
  50% {
    border-color: #0a6bcb73; /* Light border color */
  }
  100% {
    border-color: #0a6bcb; /* Dark border color */
  }
}

.MuiStepper-horizontal .Mui-active .MuiStepIndicator-root {
  animation: changeBorderColor 2s infinite;
}

.step-item-list {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    min-width: 100px !important;
  }
}
.step-card-list {
  overflow-x: auto;
}

.step-card-list::-webkit-scrollbar {
  height: 4px;
  background-color: #eee;
}
.step-card-list::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 100px;
}

.shipping-step-card {
  font-size: 0.8rem !important;
  text-align: start;
  padding: 0px !important;
  margin: 0px !important;
  cursor: default !important;
  color: #585a5a;
  font-weight: 500 !important;
  text-transform: math-auto !important;
}
