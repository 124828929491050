.Account-page {
  .op-card{
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fcfcfc;
  }
  .df {
    display: flex;
  }
  .jcsb {
    justify-content: space-between;
  }
  .ai {
    align-items: center;
  }
  .section-card { 
    padding: 20px;
  }
  .row-card { 
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile-card {
    background-color: #fcfcfc;
    padding: 30px 0px 15px 0px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
      .Category-item {
        padding: 0 !important;
      }
    }
  }
  .button-heights {
    height: 50px;
    background-color: #222945;
    border: none;
    font-size: 1rem;
    padding: 0px 20px 0px 20px;
  }
  .button-heights:hover {
    height: 50px;
    background-color: #222945;
    border: none;
  }
  .button-heights:focus {
    height: 50px;
    background-color: #222945;
    border: none;
  }
}
 
.refund-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog{
    scroll-behavior: smooth;
    max-width: 550px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content{
    border-radius: 10px;
    border: none !important;
  }
  .modal-header{
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
    border-radius: 10px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
