.Purchase-page-Success {
  height: 100vh;
  .purchase-container {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }

    .row {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
  .purchase-section {
    justify-content: center;
  }

  .container {
    width: 900px;
  }
  .success-msg {
    color: #0f5132;
    text-align: center;
    margin-top: 20px;
  }
  .success-msg svg {
    font-size: 60px;
    border: 1px solid #0f5132;
    border-radius: 30px;
    padding: 10px;
  }
  .success-msg .title {
    font-size: 15px;
    margin-top: 10px;
  }
  .success-msg .title1 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 14px !important;
    }
    font-size: 1rem;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 992px) {
  .mp0 {
    padding: 0 !important;
  }
}

.ps-message {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
  }
  display: flex;
  align-items: center;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  padding: 20px;
  svg {
    margin-bottom: 10px;
    filter: contrast(0.5);
  }
  .message {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1rem;
      text-align: center;
      margin: 0;
      width: 100%;
    }
    margin: 0px 10px;
    font-size: 1.2rem;
  }
}

.sp-section {
  .sp-card {
    background: rgba(255, 255, 255, 0);
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    .sp-heading {
      padding-bottom: 0.5rem;
      color: rgb(3, 3, 3);
      font-weight: 400;
      font-size: 1.2rem;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 1.2rem;
      }
    }
  }
  .sp-card-header {
    padding-top: 10px;
    background: rgba(255, 255, 255, 0);
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: grid;
      justify-content: flex-start;
    }
  }
  .sp-item-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
    }
  }
  .sp-item-card {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    background: rgb(255, 255, 255);
    padding: 12px;
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #eeee;
    border-radius: 10px;
  }
  .sp-item-img {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      max-width: 70px;
      min-width: 70px;
      min-height: 70px;
      max-height: 70px;
    }
    max-width: 90px;
    min-width: 90px;
    min-height: 90px;
    max-height: 90px;
    object-fit: contain;
    padding: 7px;
    border-radius: 6px;
  }
  .sp-item-title {
    margin: 0px 10px;
    width: 100%;
    a {
      color: #444;
    }
    p {
      font-size: 13px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      position: relative;
    }
  }
  .sp-item-content {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      flex-wrap: wrap;
    }
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
