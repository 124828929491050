.pagination {
  display: inline-block;
}

.pagination a {
  color: #212945;
  float: left;
  padding: 6px 12px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  outline: none !important;
  text-decoration: none !important;
  text-transform: none !important;
}

.pagination a.active {
  background-color: #212945;
  color: white;
  border: 1px solid #212945;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

a[disabled] {
  pointer-events: none !important;
  cursor: default !important;
  color: #939393 !important;
}