.pay-purchase-dialogue {
  iframe {
    border-radius: 10px !important;
    padding: 15px;
    background: #fff;
  }
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 650px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 10px;
    border: none !important;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    position: relative;
    border-radius: 10px;
    padding: 0px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.shipping-address-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 10px;
    border: none !important;
  }
  .modal-header {
    background: #fcfcfc;
    border: none;
  }
  .modal-body {
    background: #fcfcfc;
    padding: 1.3rem;
    border-radius: 10px;
    box-shadow: none;
  }
  input {
    height: 50px;
    background: #f6f5f5;
  }
  select {
    height: 50px;
    background: #f6f5f5;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .PhoneInputCountry {
    background: #f6f5f5 !important;
  }
}

.alert-text {
  background: #fff0 !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.cp-shipping-card {
  text-align: start;
  width: 100%;
  justify-content: start;
  border: 1px solid #dddddd;
  display: flex;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
}

.cp-pm-button {
  padding: 0px !important;
  border: none !important;
  color: #444 !important;
  width: 100%;
  text-align: start;
  margin-bottom: 10px !important;
}
.cp-pm-button-card {
  padding: 10px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.checkout-button-bottom {
  width: 100%;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
}

.cp-cinfo-card {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 10px;
    padding-top: 20px;
  }
  padding: 20px;
  background: #fcfcfc;
  border: 0.7px solid #00000014;
  border-radius: 7px;
}

.cp-guest-mode {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    left: 10px;
  }
  position: absolute;
  left: 20px;
  top: 0px;
}

.cp-shipping-card {
  text-align: start;
  width: 100%;
  justify-content: start;
  border: 1px solid #dddddd;
  display: flex;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
}

.cp-pm-button {
  padding: 0px !important;
  border: none !important;
  color: #444 !important;
  width: 100%;
  text-align: start;
  margin-bottom: 10px !important;
}
.cp-pm-button-card {
  padding: 10px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}
