$font-size: 1rem;
$line-height: 1.4;
$lines-to-show-heading: 3;
$lines-to-show-text: 4;
$lines-to-show-author: 2;
.statisc-page {
  padding-top: 1rem;
  .static-container-card {
    text-align: left;
    padding: 20px;
    background: #fcfcfc;
  }
  .terms-text{
    font-size: 15px;
    color: #727272;
  }
  li{
    font-size: 15px;
    color: #727272;
  }
  h5{
    font-size: 0.9rem;
    font-weight: 500;
  }
  h4{
    font-size: 1rem;
    font-weight: 500;
  }
  h3 {
    font-weight: 500;
    font-size: 2rem;
  }
  p {
    font-size: 15px;
    color: #696f75;
  }
  .static-container {
    padding: 0 !important;
  }
  .static-row {
    justify-content: center;
  }
}
