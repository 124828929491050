.Header-page {
  background: #fcfcfc82;
  top: 0;
  right: 0;
  left: 0;
  white-space: nowrap;
  z-index: 111;
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    position: relative !important;
    white-space: normal !important;
  }
  .header-md {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  swiper-button-prev .desktop-hide-trial {
    display: none;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }

  .Header-waller-connect1:hover {
    color: #212945 !important;
  }

  .table_search_card {
    position: relative;
    width: 100%;
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    right: 2px;
    top: 1px;
  }
  .table_search_icon-ar {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    left: 2px;
    top: 1px;
  }
  .book_search {
    border: 1px solid #2129453b !important;
    border-radius: 10px;
    height: 40px;
    max-width: 100%;
    min-width: 100%;
    padding-right: 40px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      border-radius: 0;
    }
  }
  .book_search:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #21294594 !important;
  }

  .Header_searchIcon_rtl {
    position: absolute;
    left: 10px;
    z-index: 999;
    svg {
      font-size: 20px;
    }
  }

  .Header_searchIcon_ltr {
    position: absolute;
    right: 10px;
    z-index: 999;
    svg {
      font-size: 20px;
    }
  }

  .Header-sub-nav-list {
    align-items: center;
  }
  .nav-list-b {
    display: none;
  }
  .nav-list-h {
    display: flex;
  }

  .Header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0px 6px 0px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
        padding: 0 !important;
      }
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      padding: 0 !important;
    }
  }

  .header-logo-ar {
    padding-left: 20px;
  }
  .header-logo-en {
    padding-right: 20px;
  }

  .Header-menu-logoName {
    font-size: 20px;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #222945;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    img {
      width: 70px;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      img {
        width: 80px !important;
      }
    }
    a {
      color: #222945;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 36px;
    }
  }

  .Header-menu-list {
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .Header-menu-list2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .mobile-hide {
      display: none !important;
    }
  }

  .Header-waller-connect1 {
    padding: 0px 5px 0px 5px;
    text-transform: capitalize;
    border-radius: 2px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    // position: relative;
    color: #000000bf;
    z-index: 1;
    svg {
      color: #484949bf !important;
      font-weight: 400 !important;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 1.8rem !important;
        height: 1.8rem !important;
      }
    }
    button {
      color: #000000bf !important;
      font-weight: 400 !important;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        min-height: auto !important;
      }
    }
    a {
      color: #000000bf !important;
      font-weight: 400 !important;
      display: flex;
      align-items: center;
      position: relative;
      padding: 6px 16px;
      justify-content: flex-start;
    }

    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 18px;
      padding: 5px 5px 5px 5px;
    }
  }

  .Header-button-connect {
    text-transform: capitalize;
    border-radius: 2px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    // position: relative;
    color: #2d2d2d;
    small {
      color: #fcfcfc;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    a {
      display: flex;
      align-items: center;
      position: relative;
      color: #2d2d2d;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 18px;
      padding: 5px 5px 5px 5px;
    }
  }
  .button-member {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      border: none !important;
      svg {
        display: block !important;
      }
    }
  }
  .button-member {
    svg {
      display: none;
    }
  }

  .Header-menu-account {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center;
    }
  }
}

a:hover {
  text-decoration: none !important;
}

.expand-style {
  margin-top: 2px;
  color: #3f3f3f;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.5rem;
}
.swiper-button-next,
.swiper-button-prev {
  z-index: 111;
  border-radius: 100px;
  padding: 0px;
  height: 0px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -30px !important;
  margin-right: -30px !important;
}
.swiper {
  position: unset !important;
}

@font-face {
  font-family: "GessTwoMedium";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../assets/fonts/ge_ss_two_medium.otf) format("opentype");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #212945;
}

.modal-content {
  background-color: whitesmoke !important;
}
.modal-content {
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: none !important;
}
.modal-body {
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.header-search-media {
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    background: #f5f5f5;
    top: 0;
    right: 0;
    left: 0;
    white-space: nowrap;
    z-index: 111;
    position: sticky;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .desktop-hide {
      display: block !important;
    }
  }

  .desktop-hide {
    display: none;
  }
  .table_search_card {
    position: relative;
    width: 100%;
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    right: 2px;
    top: 1px;
  }
  .table_search_icon-ar {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    left: 2px;
    top: 1px;
  }
  .book_search {
    border: 1px solid #2129453b !important;
    border-radius: 10px;
    height: 40px;
    max-width: 100%;
    min-width: 100%;
    padding-right: 40px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      border-radius: 0;
    }
  }
  .book_search:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #21294594 !important;
  }
}
input {
  box-shadow: none !important;
}
.header-category-list {
  .header-category-list-item {
    width: 50% !important;
    max-width: 100% !important;
    white-space: normal;
    text-align: start;
    margin-bottom: 3px;
  }
  ul {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 575px !important;
    max-width: 100%;
  }
}

.login_modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 12px !important;
  }
  .modal-content {
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border: none !important;
  }
  .modal-body {
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}

.menu-section {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: auto hidden;
  padding: 0px;
  position: relative;
  z-index: 111;
  width: 100%;
  white-space: nowrap;
  justify-content: space-between;
}

.card-section {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: auto hidden;
  padding: 0px;
  position: relative;
  z-index: 111;
  white-space: nowrap;
}

.card-section::-webkit-scrollbar {
  height: 4px;
  background-color: #eee;
}
.card-section::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 100px;
}

.scroll-menu::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: #eee;
  border-radius: 100px;
}
.scroll-menu::-webkit-scrollbar-thumb:horizontal {
  background: gray;
  border-radius: 10px;
}
.sub-menu-header {
  margin: 0 10px;
  color: #2f122e !important;
  padding: 12px 0px 12px 0px;
  font-size: 14.5px;
  display: inline-block;
}
.sub-menu-header-item{
  font-weight: 500 !important;
  color: #2f122e !important;
  font-family: Poppins, sans-serif !important;
}
.sub-menu-header:hover {
  color: #000 !important;
  text-decoration: underline !important;
}

.header-cart-value {
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: -10px;
  background: #323232;
  color: #f7f7f7;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.header-search-input {
  width: 100%;
  border: 1px solid #c4c4c4 !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid #c4c4c4 !important;
  }
  button {
    width: 38px;
    height: 38px;
    line-height: 38px;
  }
}
.header-search-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Header_searchIcon_rtl {
  position: absolute;
  left: 10px;
  z-index: 999;
  svg {
    font-size: 20px;
  }
}
.arabic-font-change {
  font-family: GessTwoMedium, sans-serif;
}

.Header_searchIcon_ltr {
  position: absolute;
  right: 10px;
  z-index: 999;
  svg {
    font-size: 20px;
  }
}
.Header-sub-nav-list {
  align-items: center;
}
.nav-list-b {
  display: none;
}
.nav-list-h {
  display: flex;
}

.side-menu-section {
  .MuiDrawer-content {
    width: 325px !important;
    max-width: 100%;
    transition: unset !important;
  }
  .MuiDrawer-backdrop {
    backdrop-filter: blur(0px) !important;
  }
}

.cart-menu-section {
  .MuiDrawer-content {
    width: 555px !important;
    max-width: 100%;
    transition: unset !important;
  }
  .MuiDrawer-backdrop {
    backdrop-filter: blur(0px) !important;
  }
}
.side-menu-list {
  .side-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
  }
}
.header-dropdown-menu {
  z-index: 999;
  ul {
    li {
      padding: 6px 16px 6px 16px;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.country-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.side-scroll-menu {
  .MuiDrawer-content::-webkit-scrollbar {
    height: 0px;
    width: 7px;
    background: #eee;
    border-radius: 0px;
  }
}
.side-scroll-menu {
  .MuiDrawer-content::-webkit-scrollbar-thumb:vertical {
    background-color: gray;
    border-radius: 10px;
  }
}

.login-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 2rem !important;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}

.otp {
  .inputStyle {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 2rem !important;
      height: 2rem;
      margin: 0 0.1rem;
      font-size: 1rem;
    }
    background: #f3f3f3;
    width: 3.5rem !important;
    height: 3.5rem;
    margin: 0 0.1rem;
    font-size: 1.2rem;
    border-radius: 4px;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    outline: none !important;
  }
  .inputStyle:hover {
    border: 0.5px solid #5eb2d9;
  }
  .inputStyle:focus {
    border: 0.5px solid #5eb2d9;
  }
  .inputStyle:active {
    border: 0.5px solid #5eb2d9;
  }
}

@media only screen and (min-width: 0px) and (max-width: 992px) {
  .circleChatButtonWrap {
    bottom: 65px !important;
  }
}

.cart-menu-section {
  z-index: 9999999 !important;
}

.ant-tag {
  white-space: normal !important;
}
.country-alert-dialogue-section {
  z-index: 111;
  position: fixed;
  bottom: 15px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    right: 30%;
  }
  right: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  .country-alert-dialogue {
    position: relative;
    .MuiAlert-message {
      margin: 0px 10px;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
    }
    .MuiAlert-action {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
}

.header-search-input {
  input::placeholder {
    font-weight: 400 !important;
  }
}
.MuiButton-variantPlain:hover {
  text-decoration: none !important;
  border: none !important;
  text-transform: none !important;
  border-bottom: none !important;
}
.sub-menu-header:hover {
  outline: none !important;
  text-decoration: none !important;
}
