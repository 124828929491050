.account-side-menu-section {
  max-width: 100%;
  border-right: 1px solid #eee;
  height: 100vh;

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    width: 44px;
    border-right: 1px solid #d0d0d0;
  }

  .syncIcon {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      svg {
        font-size: 1.2rem !important;
      }
    }
  }
}

.sideMenuN {
  width: 240px;
  background: #fcfcfc;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  behavior: "smooth";
  transition: 0s;
  padding: 10px 0px 10px 0px;
  z-index: 999;
  margin-top: 0px;
  border-right: none;
  height: 100%;
}

.sideMenuN {
  a:hover {
    background-color: #dadce0 !important;
  }
}

.menuColor {
  span {
    display: flex;
    align-items: center;
  }
  height: 46px !important;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  justify-content: flex-start !important;
  padding: 10px 10px 10px 10px !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  font-family: "Poppins", sans-serif !important;
  color: #444 !important;
  border: none !important;
  text-decoration: none !important;
  outline: none !important;
  svg {
    color: #444;
    margin: 0px 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1.2rem !important;
    }
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 0 !important;
  }
}
.account-side-menu-section {
  .ant-menu-inline-collapsed {
    width: 44px !important;
  }
}

.sideMenuN {
  .thiActive {
    color: #1890ff !important;
    svg {
      color: #1890ff !important;
    }
  }

  .menuColor:hover {
    background: #fff0 !important;
    border-radius: 0px !important;
    color: #1890ff !important;
    svg {
      color: #1890ff;
    }
  }
  .menuColor:focus {
    background: #fff0 !important;
    border-radius: 0px !important;
    color: #1890ff !important;
    svg {
      color: #1890ff;
    }
  }
}

.account-section {
  display: flex;
  // border-right: 1px solid #eee;
  // border-left: 1px solid #eee;
  // border-bottom: 1px solid #eee;
  background-color: #f7f7f7;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  .op-section {
    padding: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 5px;
    }
  }
}
