.Alert__page {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  top: 0;
  .Alert__message {
    width: 100%;
    justify-content: center;
  }
  .form-control {
    border: 1px solid #eee;
    height: 60px;
    box-shadow: none !important;
  }
  .card {
    padding: 45px;
    border: 1px solid #f0f0f0;
  }
  .Alert__Heading span {
    font-weight: 600;
    text-align: left;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px !important;
      white-space: nowrap;
    }
  }
  .Alert__Heading {
    text-align: left;
  }

  .Alert__Heading {
    margin-bottom: auto;
  }
  .Alert__Heading2 span {
    color: #3d2b93;
    font-weight: 800;
  }
  .card p {
    color: #3d2b93;
  }
  .dFlex {
    display: flex;
    align-items: center;
  }
  .EightPlus {
    background: #3d2c92;
    color: #fff;
    padding: 2px;
    border-radius: 4px;
    margin-right: 10px;
  }
  .EightPlus_Text {
    font-size: 12px;
    font-weight: 800;
    margin: 0;
    color: #3d2c92;
  }
  .Access-site {
    display: flex;
    align-items: center;
    margin-top: 20px;
    input {
      height: 50px;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }
  .Access-site-button {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
  }
  .danger {
    text-align: center;
    padding: 20px;
    width: 100%;
    color: #c11717;
  }
}

.otp-verify {
  justify-content: center;
}

.pg-m-pd {
  @media only screen and (min-width: 0px) and (max-width: 1140px) {
    padding: 0 !important;
    flex-direction: column-reverse;
  }
}

.pg-m-center {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .op-o-info{
      font-size: 14px !important;
    }
    text-align: center;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
  }
}
