.PhoneInputCountry {
  background-color: #fff !important;
  background-clip: padding-box;
  padding: 0px 10px;
  margin: 0px !important;
}
.coupon-input{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.coupon-input:hover{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.coupon-input:focus{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.coupon-section{
  border: 1px solid #eee !important;
  border-radius: 4px;
}


.PhoneInput {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.Purchase-page {
  .pp-info-section { 
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background: #fff;
      z-index: 111;
      border: 1px solid #dcdcdc;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; 
      button{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
  }

  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  margin-top: 30px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-top: 0px;
  }
  .cart-page-card {
    display: flex;
    .img-card {
      min-height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 80px;
      span {
        min-height: 50%;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80px;
      }
      .img-item {
        height: 80px;
        object-fit: contain;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        z-index: 2;
        position: relative;
      }
    }
  }
  .purchase-button-bottom {
    // position: sticky;
    // bottom: 0;
    width: 100%;
    z-index: 111;
    button {
      padding: 6px 15px;
    }
  }
  .pp-padding {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0;
    }
  }
  .ppbt {
    width: 100%;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0px;
      width: 100%;
    }
  }
  .purchase-container {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .purchase-card-list {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px;
    }
    svg {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .select-payment-method {
    padding: 10px;
    background: whitesmoke;
    border: 1px solid rgb(238, 238, 238);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .select-payment-method-select {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dFlex {
    display: flex;
    align-items: center;
  }
  .padding20 {
    padding: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .p0 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .padding-card-form {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px 0px 0px 0px !important;
    }
    padding: 20px 10px 0px 10px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr0 {
    margin: 0;
  }
  .purchase-coupon-input {
    width: 100%;
    max-width: 100%;

    input:focus {
      box-shadow: none !important;
    }
  }

  .purchase-complete-order-text {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 22px !important;
      text-align: center;
    }
  }

  .payment-method-card {
    background: #eee;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .remove_payment {
      margin-left: 10px;
    }
  }
  .purchase-created-success {
    color: #126735;
    text-align: center;
    font-size: 26px;
    line-height: 1;
  }
  .purchase-created-error {
    color: firebrick;
    font-size: 14px;
    padding-bottom: 40px;
    text-align: center;
  }
  .purchase-created-icon {
    text-align: center;
    padding: 30px;
    svg {
      color: #067a06;
      font-size: 42px;
    }
  }
  .proceed-button {
    width: 100%;
  }
  .purchase-section {
    justify-content: center;
  }
  .purchase-form-card {
    padding: 0px;
    word-break: break-all;
    .purchase-button {
      width: 100%;
    }
  }
  .purchase-info-card {
    background: #fcfcfc;
    border: 1px solid #eee;
    word-break: break-all;
    border-radius: 10px;
    .purchase-info-sub-card {
      padding: 20px;
    }
    .purchase-infodiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        h4 {
          font-size: 16px !important;
        }
      }
    }

    .purchase-infodivl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
        h4 {
          font-size: 16px !important;
        }
      }
    }
    .purchase-right-footer {
      text-align: center;
      padding: 20px;
      background: #252734;
      h6 {
        color: #f7ee23;
      }
      p {
        color: #f7ee23;
      }
    }
  }
}

.margin-bottom {
  margin-bottom: 15px;
}

.sign-button {
  padding: 20px;
  border: 1px solid #eee;
  text-align: center;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 900px;
}
.s-box {
  min-height: 433px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  background-color: #f7f7f9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}
label {
  margin-bottom: 3px;
}
.spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  border-width: 0.1em;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 20px;
}
.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}
.success-msg .title {
  font-size: 15px;
  margin-top: 10px;
}
.success-msg .title1 {
  font-size: 22px;
  margin-top: 10px;
}
.order-md-1 {
  padding: 0 !important;
}

.form-control-custom {
  height: 50px !important;
  padding: 11px !important;
  border: 1px solid #eee !important;
}
.form-control-custom:focus {
  box-shadow: none !important;
}

.coloredButton {
  background: #191a1a !important;
  color: #fcfcfc !important;
  border: 1px solid #191a1a !important;
  height: 50px;
}
.inquiry-form-button {
  color: #fcfcfc !important;
  background-color: #191a1a !important;
  border-color: #191a1a !important;
  width: 100%;
  height: 46px;
}
// .fixedBottom {
//   @media only screen and (min-width: 0px) and (max-width: 992px) {
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     z-index: 999;
//   }
// }
.accept-text {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 11px;
  }
  font-size: 14px;
}

.expiry-date-card {
  width: 50% !important;
  max-width: 50% !important;
  flex: 50% !important;
}
.csv-card {
  width: 50% !important;
  max-width: 50% !important;
  flex: 50% !important;
}

.shipping-dialogue {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 10px;
    border: none !important;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
    border-radius: 10px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.card-dialogue {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 500px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 10px;
    border: none !important;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
    border-radius: 10px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.ellipsis-title-2 {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


 
.hidden-payment-request-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.custom-payment-button {
  cursor: pointer;
  width: 100%;
}
