.cart-page {
  .cp-buttons-sticky {
    display: flex;
    @media only screen and (min-width: 0px) and (max-width: 340px) {
      flex-wrap: wrap;
    }
    align-items: center;
    padding: 10px 10px;
    position: sticky;
    top: 0;
    z-index: 111;
    background: #fbfcfe;
    width: 100%;
    .cp-outline-buttons {
      @media only screen and (min-width: 0px) and (max-width: 340px) {
        margin-top: 5px !important;
      }
    }
  }
  .purchase-info-sub-card {
    background-color: #f7f7f7;
    padding: 15px 15px 0px 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 0.7px solid #00000026;
  }
  .cart-font-size {
    font-size: 1rem;
  }
  .purchase-coupon-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .purchase-infodiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    font-size: 13px;
  }
  margin-top: 0px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-top: 0px;
  }
  // .purchase-info-card {
  //   @media only screen and (min-width: 0px) and (max-width: 992px) {
  //     margin-bottom: 10px;
  //   }
  // }
  .cpc-button {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    button {
      padding: 6px 15px;
    }
  }
  .cp-card-item {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0px;
    }
  }
  // .cp-card-row {
  //   @media only screen and (min-width: 0px) and (max-width: 992px) {
  //     flex-direction: column-reverse;
  //   }
  // }
  .cardTitle {
    padding: 20px 5px 0px 5px;
    font-size: 1.2rem;
    text-align: start;
    font-weight: 400;
    text-align: center;
  }
  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .cart-page-card {
    background-color: #fff;
    display: flex;
    @media only screen and (min-width: 0px) and (max-width: 340px) {
      flex-wrap: wrap;
    }
    padding: 8px;
    // cursor: pointer;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    border: 1px solid #e3e3e3;
    .img-card {
      min-height: 50%;
      display: flex;
      @media only screen and (min-width: 0px) and (max-width: 340px) {
        justify-content: center;
        width: 100%;
      }
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 120px;
      .MuiSkeleton-pulse {
        background: #ebebeb;
      }
      .img-item {
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          min-height: 75px;
          max-height: 75px;
          max-width: 75px;
          min-width: 75px;
        }
        min-height: 75px;
        max-height: 75px;
        max-width: 75px;
        min-width: 75px;
        object-fit: contain;
        margin-right: auto;
        margin-left: auto;
        z-index: 2;
        position: relative;
      }
    }
  }
  .img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-right: 20px;
  }
  .cart-page-text {
    margin: 0 10px;
    width: 90%;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #030303;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 11px !important;
      }
    }
    .price {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      padding-top: 10px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px !important;
        padding-bottom: 10px;
      }
    }
    .brand {
      color: #8e8e8e;
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  .cart-remove-button {
    position: relative;
    border: 0.7px solid #00000014;
    svg {
      font-size: 15px;
    }
  }
}

.cp-outline-buttons {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: rgb(25, 118, 210);
}

.cp-buttons {
  padding: 5px 15px;
  border-radius: 4px;
  display: inline-flex;
}
.cp-title{
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  position: relative;
}
