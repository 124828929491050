.pay-purchase-dialogue {
  iframe {
    border-radius: 10px !important;
    padding: 15px;
    background: #fff;
  }
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 650px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 10px;
    border: none !important;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    position: relative;
    border-radius: 10px;
    padding: 0px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
