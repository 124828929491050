.sp-style {
  .fd-carousel {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
    }
  }

  .sp-search-loader {
    position: relative;
    right: 0;
    left: 0;
    margin: 20px 10px;
    height: 100%;
    z-index: 999;
    background: #ffffff70;
  }
  .search-cardTitle {
    padding: 0px 5px 0px 5px;
    font-size: 1.2rem;
    text-align: start;
    font-weight: 400;
    @media only screen and (min-width: 0px) and (max-width: 768px) {
      display: flex;
      justify-content: center;
      padding: 10px 5px 10px 5px;
    }
  }
  .search-result-secton {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
  .sp-query {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 1.5;
    margin: 0;
    overflow: hidden;
    position: relative;
    max-width: 100%;
  }
  .no-result-found-secton {
    text-align: center;
    width: 100%;
    padding: 100px;
    svg {
      font-size: 3rem;
      color: #a5a5a5;
      filter: opacity(0.5);
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 20px;
      .cardTitle {
        font-size: 1rem !important;
      }
      svg {
        font-size: 2rem;
      }
    }
  }
  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .search-paginattion {
    align-items: center;
    background: #fff;
    bottom: 0;
    display: flex;
    justify-content: right;
    padding: 10px;
    position: -webkit-sticky;
    position: relative;
    z-index: 111;
  }
  .cardTitle {
    padding: 20px 5px 0px 5px;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 400;
    .skeleton {
      background-color: #f6f6f6 !important;
    }
  }

  .cardItem {
    .MuiImageListItem-item {
      overflow: unset !important;
    }
    @media only screen and (min-width: 0px) and (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    flex: 0 0 20%;
    max-width: 20%;
    height: auto !important;
    padding: 5px !important;
    position: relative;
    .local-store-card-item{
      display: flex !important;
    }
    .cardItem-item {
      background-color: #fff;
      display: block;
      cursor: pointer;
      justify-content: space-between;
      transition: all 0.2s ease-in-out;
      border-radius: 8px;
      border: 0.7px solid #00000026;
      word-break: break-all;
      @media only screen and (min-width: 0px) and (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
      }
      .skeleton {
        background-color: #f6f6f6 !important;
      }
    }
    .imgCard {
      height: 210px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        height: 110px;
      }
    }
    .img-card {
      min-height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .MuiSkeleton-pulse {
        background: #ebebeb;
      }
      .img-item {
        height: 210px;
        object-fit: contain;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        z-index: 2;
        position: relative;
        padding: 15px;
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          height: 110px;
          padding: 10px;
        }
        @media only screen and (min-width: 0px) and (max-width: 768px) {
          min-width: 115px;
          max-width: 115px;
        }
      }
    }
    .card-content {
      .title-card {
        display: block;
        min-height: 61px;
        max-height: 62px;
        width: 100%;
      }
      .brand {
        font-size: 12px;
        color: #8b8b8b;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        position: relative;
        margin: 0;
      }
      .rating {
        font-size: 12px;
      }
      .MuiSkeleton-pulse {
        background: #ebebeb;
      }
      padding: 10px;
      height: 130px;
      .content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
      }
      h4 {
        font-size: 14px;
        line-height: 1.5;
        color: #444;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: relative;
        width: 100%;
        margin: 0;
      }
      h5 {
        margin: 0;
        font-size: 16px;
        color: #444;
      }
    }
  }
}

.content-item {
  .item-price-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 15px;
    .item-deal-price {
      color: #2f122e;
      font-weight: 500;
    }
  }
}
.item-price {
  white-space: nowrap;
  color: #721c24;
  font-weight: 500;
}

@media only screen and (min-width: 0px) and (max-width: 992px) {
  .mobile-hide {
    display: none !important;
  }
}
