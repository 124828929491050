.checkout-t-section {
  div {
    margin: 0 !important;
  }
}

.pp-2-padding {
  padding: 20px;
  background: #fcfcfc;
  border: 0.7px solid #00000014;
  border-radius: 10px;
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    padding: 5px !important;
    .pp-o-title{
      margin-bottom: 0;
      margin-top: 20px;

    }
  }
  .pp-o-title{
    padding-bottom: 15px;
    color: rgb(3, 3, 3);
    font-weight: 400;
    font-size: 1.1rem;
  }
}

.a-m-style {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  border: none;
}
