#card-sdk-id {
  div {
    max-width: 100% !important;
  }
}

.button-applepay-tap {
  height: auto !important;
  width: auto !important;
  border-radius: 6px !important;
}

.card-payment-style{
  margin-left: 5px !important;
  div{
    margin: 0 !important;
  }
}
