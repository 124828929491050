.search-loader {
  display: flex;
  justify-content: center;
}

.cardTitle{
  .search-loader > div {
    width: 12px;
    height: 12px;
    margin: 1px 3px;
    border-radius: 50%;
    background-color: #fe7f4ad4;
    opacity: 1;
    animation: search-loader 0.6s infinite alternate;
  }
}

.search-loader > div {
  width: 12px;
  height: 12px;
  margin: 1px 3px;
  border-radius: 50%;
  background-color: #fe7f4ad4;
  opacity: 1;
  animation: search-loader 0.6s infinite alternate;
}
@keyframes search-loader {
  to {
    opacity: 0.1;
    transform: translateY(-0px);
  }
}

.search-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.search-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
